import React, {Component} from 'react';
import SwipeableViews from "react-swipeable-views";
import Quagga from "quagga";
import {withAlert} from "react-meerkat";
import {postJson} from "../../request";
import logo from "../../assets/img/Logo.png";
import ean13 from "../../assets/img/ean13.gif";
import ReCAPTCHA from "react-google-recaptcha";

class Scanner extends Component {
	constructor() {
		super();

		this.state = {
			step: 0,
			code: "",
			loader: false,
			streamIsActive: false,
			error: false,
			streamShouldReconnect: false,
			captcha: false,
		};

		this.videoStream = React.createRef();
		this.videoStreamCanvas = React.createRef();
		this.captchaDemo = React.createRef();

		this.verifyCallback = this.verifyCallback.bind(this);
	}

	componentDidMount() {
		if (this.captchaDemo.current) {
			this.captchaDemo.reset();
		}
	}
	verifyCallback(value) {
		if (this.captchaDemo.current) {
			this.captchaDemo.reset();
		}
		this.setState({
			captcha: false
		});
		console.log(value, "<= your recaptcha token")
	}

	createVideoStream() {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({
				video: {
					width: 640,
					height: 480,
					facingMode: "environment"
				}
			})
			.then(stream => {
				this.videoStream.current.srcObject = stream;
				this.videoStream.current.onloadedmetadata = (e) => {
					this.videoStream.current.play();
					this.startStream();
				};
			})
			.catch(fail => {
				this.props.alert.show('Ошибка захвата камеры устройства!');
				this.setState({
					error: true
				});
			});
		} else {
			this.props.alert.show('Ошибка браузера, попробуйте в другом браузере!');
			this.setState({
				error: true
			});
		}
	}

	startStream() {
		Quagga.init({
			inputStream: {
				name: "Live",
				type: "LiveStream",
				target: this.videoStreamCanvas.current
			},
			area: {
				top: "8.3%",    // top offset
				right: "6.25%",  // right offset
				left: "6.25%",   // left offset
				bottom: "8.3%"  // bottom offset
			},
			decoder: {
				readers: ["ean_reader"]
			}
		}, (err) => {
			if (err) {
				this.props.alert.show('Ошибка инициализации, попробуйте снова!');
				this.setState({
					error: true
				});
				return
			}
			console.log("Initialization finished. Ready to start");
			Quagga.start();
			if (this.videoStream.current) {
				this.videoStreamCanvas.current.getContext('2d').clearRect(0, 0, 640, 480);
				Quagga.ImageDebug.drawPath([
					[40, 440],
					[40, 40],
					[600, 40],
					[600, 440],
				], {x: 0, y: 1}, this.videoStreamCanvas.current.getContext('2d'), {color: '#e43b72', lineWidth: 3});
				this.setState({
					streamIsActive: true
				});
			}
		});
		Quagga.onDetected((data) => {
			Quagga.stop();
			if (this.videoStream.current) {
				this.videoStream.current.srcObject.getTracks().forEach(track => track.stop());
				this.videoStream.current.pause();
				this.setState({
					code: data.codeResult.code,
					streamIsActive: false,
					streamShouldReconnect: true
				})
			}
		});
		Quagga.onProcessed((result) => {
			if (this.videoStream.current) {
				let drawingCtx = this.videoStreamCanvas.current.getContext('2d');
				if (result) {
					drawingCtx.clearRect(42, 42, 556, 396);

					if (result.codeResult && result.codeResult.code) {
						this.videoStreamCanvas.current.getContext('2d').drawImage(this.videoStream.current, 0, 0);
						Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {
							color: 'red',
							lineWidth: 3
						});
					}

					if (result.box) {
						Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
					}
				}
			}
		});
	}

	componentWillUnmount() {
		if (this.state.streamIsActive) {
			Quagga.stop();
		}
		if (this.videoStream.current && this.videoStream.current.srcObject) {
			this.videoStream.current.srcObject.getTracks().forEach(track => track.stop());
			this.videoStream.current.pause();
		}
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.state.step === 1 && !this.state.streamIsActive && !this.state.error && !this.state.streamShouldReconnect) {
			this.createVideoStream()
		}
		if (this.state.code.length === 13 && prevState.code !== this.state.code) {
			this.sendRequest();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState !== this.state;
	}


	changeStep(step) {
		if (this.state.streamIsActive) {
			Quagga.stop();
			this.videoStream.current.srcObject.getTracks().forEach(track => track.stop());
			this.videoStream.current.pause();
			this.videoStream.current.srcObject = null;
		}
		this.setState({
			step: step,
			streamIsActive: false
		})
	}

	handleChangeIndex = index => {
		this.changeStep(index);
	};

	sendRequest() {
		postJson('barcode', {barCode: this.state.code})
			.then((response) => {
				if (response.status === 400) {
					throw Error('Неверный код, попробуйте снова!');
				}
				if (response.status !== 200) {
					throw Error('Ошибка соединения, попробуйте снова!');
				}
				return response.json();
			})
			.then(response => {
				if (response.needReCaptcha) {
					this.setState({
						captcha: true
					});
					throw Error('Введите каптчу!');
				}
				this.props.setPackage(response, this.state.code);
			})
			.catch(error => {
				this.props.alert.show(error.message);
				this.setState({
					loader: false
				})
			});
		this.setState({
			loader: true
		});
	}

	render() {
		return (
			<div className="Scanner">
				{this.state.loader &&
				<div className="loader"></div>
				}
				<img src={logo} alt="Logo" className="logo"/>
				<SwipeableViews index={this.state.step} enableMouseEvents onChangeIndex={this.handleChangeIndex}>
					<React.Fragment>
						<div className="container page-1">
							<span className="desc">Поднесите карту к камере, чтобы сканировать штрих - код</span>
							<img src={ean13} alt="Example" className="example"/>
							<button className="btn scan" onClick={() => this.changeStep(1)}>Сканировать</button>
						</div>
					</React.Fragment>
					<React.Fragment>
						<div className="container page-2">
							<div className="videoContainer">
								<div className="correctionSize">
									<video className="videoStream" ref={this.videoStream}/>
									<canvas className="videoStreamOverlay" width="640" height="480"
									        ref={this.videoStreamCanvas}/>
								</div>
							</div>
							<button className="btn repeat" onClick={() => this.setState({streamShouldReconnect: false})}>Повторить</button>
						</div>
					</React.Fragment>
					<React.Fragment>
						<div className="container page-3">
							<span className="desc">Введите номер штрих-кода с карты продукта</span>
							<input type="password" className="codeInput" value={this.state.code} onChange={(e) => {
									this.setState({
										code: e.target.value.length <= 13 ? e.target.value : this.state.code
									})
							}} disabled={this.state.loader}/>
							{this.state.captcha && (<ReCAPTCHA
								ref={this.captchaDemo}
								sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
								theme="dark"
								onChange={this.verifyCallback}
								onErrored={(error) => console.log(error)}
							/>
							)}
						</div>
					</React.Fragment>
				</SwipeableViews>
				<div className="bottom">
					<div className="circles">
						<i className={this.state.step === 0 ? "active" : ""} onClick={() => this.changeStep(0)}> </i>
						<i className={this.state.step === 1 ? "active" : ""} onClick={() => this.changeStep(1)}> </i>
						<i className={this.state.step === 2 ? "active" : ""} onClick={() => this.changeStep(2)}> </i>
					</div>
					<span className="lastPage" onClick={() => this.changeStep(2)}>Ввести вручную</span>
				</div>
			</div>
		);
	}
}

export default withAlert(Scanner);
