import React, {Component} from 'react';
import StartScreen from "../StartScreen/StartScreen";
import Scanner from "../Scanner/Scanner";
import MainScreen from "../MainScreen/MainScreen";
import LeftMenu from "../LeftMenu/LeftMenu";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentWindow: "StartScreen",
			currentPackage: null,
			barcode: null
		};
	}

	changeScreen = (name) => {
		this.setState({
			currentWindow: name
		})
	};

	setupPackage = (packages, barcode) => {
		this.setState({
			currentPackage: packages,
			currentWindow: "MainScreen",
			barcode: barcode
		})
	};

	clearStates = () => {
		this.setState({
			currentWindow: "StartScreen",
			currentPackage: null,
			barcode: null
		})
	};

	render() {
		return (
			<div className="App">
				{this.state.currentWindow === "StartScreen" &&
				<StartScreen clickHangler={this.changeScreen}/>
				}
				{this.state.currentWindow === "Scanner" &&
				<Scanner clickHangler={this.changeScreen} setPackage={this.setupPackage}/>
				}
				{this.state.currentWindow === "MainScreen" &&
				<MainScreen changeScreen={this.changeScreen} currentPackage={this.state.currentPackage} barcode={this.state.barcode}/>
				}
				{this.state.currentWindow === "LeftMenu" &&
				<LeftMenu changeScreen={this.changeScreen} clearStates={this.clearStates}/>
				}
			</div>
		);
	}
}

export default App;
