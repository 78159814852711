export const version = process.env.REACT_APP_API_VERSION;
export const globalUrl = process.env.REACT_APP_API_URL;

export function getRequest (url) {
	return sendRequest('GET', url, null, null)
}

export function postRequest(url, data, type = null) {
	return sendRequest('POST', url, data, type);
}

export function postJson(url, data) {
	return sendRequest('POST', url, JSON.stringify(data), 'application/json');
}

export function putRequest(url, data, type = null) {
	return sendRequest('PUT', url, data, type);
}

export function deleteRequest(url) {
	return sendRequest('DELETE', url, null, null);
}

const sendRequest = async (method, url, data, contentType) => {
	contentType = contentType ? contentType : 'application/x-www-form-urlencoded';
	let options = {
		method: method,
		headers: {
			'Content-Type': contentType
		},
		body: data
	};

	return await fetch(globalUrl + url, options);
};