import React, {Component} from 'react';
import SwipeableViews from "react-swipeable-views";
import {putRequest} from "../../request";
import {withAlert} from "react-meerkat";
import {copyToClipboard} from "../helpers";

class MainScreen extends Component {

	constructor() {
		super();

		this.state = {
			package: null,
			steps: [],
			currentDatabaseId: 0,
			currentApplication: null,
			showPopupConfirmGetKey: false,
			showPopupKey: false,
			showPopupInfo: false,
			currentKey: "",
			copied: false,
			currentCat: null,
			currentIndexCat: 0,
			loading: false
		};
	}

	handleChangeIndex = (catIndex, index) => {
		let steps = this.state.steps;
		steps[catIndex] = index;
		this.setState({
			steps: steps,
		})
	};

	showGetKeyPopup = (key, indexCat) => {
		if (this.state.package.categories[indexCat].activeApp) {
			if (key === this.state.package.categories[indexCat].activeApp.keyDataBaseID) {
				this.setState({
					showPopupConfirmGetKey: false,
					showPopupKey: true,
					showPopupInfo: false,
					currentKey: this.state.package.categories[indexCat].activeApp.key
				})
			} else {
				this.props.alert.show("Было активировано другое приложение!");
			}
		} else {
			this.setState({
				currentDatabaseId: key,
				showPopupConfirmGetKey: true,
				showPopupInfo: false
			})
		}
	};

	getKey = () => {
		putRequest("Key/activation", JSON.stringify({
			barcode: this.props.barcode,
			keyDatabaseID: this.state.currentDatabaseId
		}), 'application/json')
		.then((response) => {
			if (response.status === 400) {
				throw Error('Код уже был активирован!');
			}
			if (response.status !== 200) {
				throw Error('Ошибка соединения, попробуйте снова!');
			}
			return response.json();
		})
		.then(response => {
			this.setState({
				showPopupConfirmGetKey: false,
				showPopupKey: true,
				showPopupInfo: false,
				currentKey: response.keyValue,
				loading: false,
				package: response.package
			})
		})
		.catch(error => {
			this.props.alert.show(error.message);
			this.setState({
				loading: false
			})
		});
		this.setState({
			loading: true
		})
	};

	closePopup = () => {
		this.setState({
			showPopupConfirmGetKey: false,
			showPopupKey: false,
			showPopupInfo: false,
			copied: false,
			currentDatabaseId: 0,
			currentKey: "",
			currentApplication: null,
			currentCat: null,
			currentIndexCat: 0
		})
	};


	componentWillMount() {
		let steps = [];
		this.props.currentPackage.categories.forEach((cat, indexCat) => {
			if (!this.state.steps[indexCat]) {
				steps[indexCat] = 0;
			}
		});
		this.setState({
			steps: steps,
			package: this.props.currentPackage
		})
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextState.showPopupKey) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}


	render() {

		let screens = this.state.package.categories.map((cat, indexCat) => {
			let catName = cat.name;
			let applications;
			let circles;
			if (cat.isPaid) {
				applications = cat.applications.map((app) => {
					return (
						<div key={app.id} className="application">
							<img src={app.logoUrl} alt={app.name}/>
							<i className="moreInfo" onClick={() => this.setState({showPopupInfo: true, currentApplication: app, currentCat: cat, currentIndexCat: indexCat})}> </i>
						</div>
					)
				});
				circles = cat.applications.map((app, index) => {
					return (
						<i key={index} className={this.state.steps[indexCat] === index ? "active" : ""}
						   onClick={() => this.handleChangeIndex(indexCat, index)}> </i>
					)
				});
			} else {
				let newApplications = new Array(Math.round(cat.applications.length / 2)).fill(null);
				applications = newApplications.map((nullArray, index) => {
					let app = cat.applications[index*2];
					let app2 = cat.applications[index*2+1];
					return (
						<React.Fragment key={"cat"+index}>
							<div key={app.id} className="application-2x" style={{background:app.primeColor}}>
								<img src={app.iconUrl} alt={app.name}/>
								<span className="name">{app.name}</span>
								{/*<i className="moreInfo" onClick={() => this.setState({showPopupInfo: true, currentApplication: app, currentCat: cat, currentIndexCat: indexCat})}> </i>*/}
							</div>
							{app2 &&
							<div key={app2.id} className="application-2x" style={{background:app2.primeColor}}>
								<img src={app2.iconUrl} alt={app2.name}/>
								<span className="name">{app2.name}</span>
								{/*<i className="moreInfo" onClick={() => this.setState({showPopupInfo: true, currentApplication: app2, currentCat: cat, currentIndexCat: indexCat})}> </i>*/}
							</div>
							}
						</React.Fragment>
					)
				});
				circles = newApplications.map((app, index) => {
					return (
						<i key={index} className={this.state.steps[indexCat] === index ? "active" : ""}
						   onClick={() => this.handleChangeIndex(indexCat, index)}> </i>
					)
				});
			}
			return (
				<div key={cat.priority} className={(cat.isPaid) ? "category" : "category cat-2x"}>
					<h2>{catName}</h2>
					<div className="slider">
						<SwipeableViews index={this.state.steps[indexCat]} enableMouseEvents onChangeIndex={this.handleChangeIndex.bind(this, indexCat)}>
							{applications}
						</SwipeableViews>
					</div>

					<div className="controls">
						<div className="links">
							{cat.isPaid &&
							<div className="link-path">
								<a target="_blank" className="download" rel="noopener noreferrer"
								   href={cat.applications[this.state.steps[indexCat]].boxUrl}>Скачать</a>
								<a target="_blank" className="googlePlay" rel="noopener noreferrer"
								   href={cat.applications[this.state.steps[indexCat]].googlePlayUrl}>Google</a>
								<span className={!this.state.package.categories[indexCat].activeApp ? "paid" : (this.state.package.categories[indexCat].activeApp &&
									cat.applications[this.state.steps[indexCat]].keyDataBaseID === this.state.package.categories[indexCat].activeApp.keyDataBaseID) ? "paid" : "paid lower" }
								      onClick={() => this.showGetKeyPopup(cat.applications[this.state.steps[indexCat]].keyDataBaseID, indexCat)}>
									{this.state.package.categories[indexCat].activeApp &&
										cat.applications[this.state.steps[indexCat]].keyDataBaseID === this.state.package.categories[indexCat].activeApp.keyDataBaseID &&
										<span>Получить ключ</span>
									}
									{!this.state.package.categories[indexCat].activeApp &&
										<span>Получить ключ</span>
									}
									</span>
							</div>
							}
							{!cat.isPaid &&
							<div className="link-path">
								<a target="_blank" className="download" rel="noopener noreferrer"
								   href={cat.applications[this.state.steps[indexCat] * 2].boxUrl}>Скачать</a>
								<a target="_blank" className="googlePlay" rel="noopener noreferrer"
								   href={cat.applications[this.state.steps[indexCat] * 2].googlePlayUrl}>Google</a>
								{cat.applications[this.state.steps[indexCat] * 2 + 1] &&
								<React.Fragment>
									<a target="_blank" className="download second" rel="noopener noreferrer"
									   href={cat.applications[this.state.steps[indexCat] * 2 + 1].boxUrl}>Скачать</a>
									<a target="_blank" className="googlePlay second" rel="noopener noreferrer"
									   href={cat.applications[this.state.steps[indexCat] * 2 + 1].googlePlayUrl}>Google</a>
								</React.Fragment>
								}
								{!cat.applications[this.state.steps[indexCat] * 2 + 1] &&
								<React.Fragment>
									<a target="_blank" className="download second disabled" rel="noopener noreferrer"
									   href="/">Скачать</a>
									<a target="_blank" className="googlePlay second disabled" rel="noopener noreferrer"
									   href="/">Google</a>
								</React.Fragment>
								}
							</div>
							}
						</div>
						<div className="steps">
							{circles}
						</div>
					</div>
				</div>
			)
		});

		return (
			<div className="MainScreen">
				{this.state.loading &&
				<div className="loader"></div>
				}
				<div className="topBar" style={{"background": this.state.package.retailer.primeColor}}>
					<img src={this.state.package.retailer.logoURL} alt={this.state.package.retailer.name}
					     className="logo"/>
					<div className="navigation" onClick={() => this.props.changeScreen("LeftMenu")}> </div>
				</div>
				<div className="categories" style={this.state.showPopupInfo ? {display:"none"} : {}}>
					{screens}
				</div>

				{this.state.showPopupConfirmGetKey &&
				<div className="popup popupConfirmGetKey">
					<span className="close" onClick={this.closePopup}> </span>
					<span className="desc">
						Вы действительно хотите получить ключ активации?
					</span>
					<div className="controls">
						<span className="buttonPopup" onClick={this.closePopup}>Нет</span>
						<span className="buttonPopup" onClick={this.getKey}>Да</span>
					</div>
				</div>
				}

				{this.state.showPopupKey &&
				<div className="popup popupKey">
					<span className="close" onClick={this.closePopup}> </span>
					<span className="desc">
						Ключ активации
					</span>
					<div className="keyArea">
						<span className="key">
							{this.state.currentKey}
						</span>
					</div>
					<span className="copyToClipboard" onClick={() => {
						copyToClipboard(this.state.currentKey);
						this.setState({
							copied: true
						})
					}}>Копировать</span>
					{this.state.copied &&
						<span className="copied">Ключ скопирован в буфер обмена</span>
					}
				</div>
				}

				{this.state.showPopupInfo &&
				<div className="infoPopup">
					<span className="close" onClick={this.closePopup}> </span>
					<span className="titleCat">{this.state.currentCat.name}</span>
					<img src={this.state.currentApplication.logoUrl} alt={this.state.currentApplication.name}/>
					<div className="content">
						<h2>{this.state.currentApplication.name}</h2>
						<p>{this.state.currentApplication.description}</p>
					</div>
					<div className="controls">
						<div className={this.state.currentCat.isPaid ? "links" : "links-2x"}>
							<div className="link-path">
							<a target="_blank" className="download" rel="noopener noreferrer"
							   href={this.state.currentApplication.boxUrl}>Скачать</a>
							<a target="_blank" className="googlePlay" rel="noopener noreferrer"
							   href={this.state.currentApplication.googlePlayUrl}>Google</a>
							{this.state.currentCat.isPaid &&
							<span className={!this.state.package.categories[this.state.currentIndexCat].activeApp ? "paid" : (this.state.package.categories[this.state.currentIndexCat].activeApp &&
								this.state.currentApplication.keyDataBaseID === this.state.package.categories[this.state.currentIndexCat].activeApp.keyDataBaseID) ? "paid" : "paid lower" }
							      onClick={() => this.showGetKeyPopup(this.state.currentApplication.keyDataBaseID, this.state.currentIndexCat)}>
								{this.state.package.categories[this.state.currentIndexCat].activeApp &&
								this.state.currentApplication.keyDataBaseID === this.state.package.categories[this.state.currentIndexCat].activeApp.keyDataBaseID &&
								<span>Получить ключ</span>
								}
								{!this.state.package.categories[this.state.currentIndexCat].activeApp &&
								<span>Получить ключ</span>
								}
							</span>
							}
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		);
	}
}

export default withAlert(MainScreen);
