import React, {Component} from 'react';
import logo from "../../assets/img/Logo.png";
import Video from "../../assets/video.mp4";

class StartScreen extends Component {
	render() {
		return (
			<div className="StartScreen">
				<div className="video">
					<video src={Video} autoPlay loop muted/>
				</div>
				<div className="content">
					<img src={logo} alt="Logo" className="logo"/>
					<span className="title">Cloud Store</span>
					<span className="desc">Для автоматической установки приложений в торговых сетях</span>
				</div>
				<button className="btn continue" onClick={() => this.props.clickHangler('Scanner')}>Начать Работу</button>
			</div>
		);
	}
}

export default StartScreen;