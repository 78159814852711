import React, {Component} from 'react';
import {withAlert} from "react-meerkat";
import {postJson} from "../../request";

class LeftMenu extends Component {
	constructor() {
		super();
		this.state = {
			open: false,
			title: "",
			email: "",
			text: ""
		}
	}

	submit = e => {
		e.preventDefault();
		postJson('Feedback', {title: this.state.title, email: this.state.email, message: this.state.text})
			.then((response) => {
				if (response.status !== 200) {
					throw Error('Ошибка соединения, попробуйте снова!');
				}
				//return response.json();
			})
			.then(response => {
				this.props.alert.show("Сообщение было отправленно!", {
					type: 'success'
				});
				this.setState({
					title: "",
					email: "",
					text: ""
				})
			})
			.catch(error => {
				this.props.alert.show(error.message);
			});
	};

	render() {
		return (
			<div className="LeftMenu">
				<span className="close" onClick={() => this.props.changeScreen("MainScreen")}> </span>
				<h2 className="title">Меню</h2>
				<ul className="menu">
					<li className="language"><span>Язык интерфейса</span><span className="right">RU</span></li>
					<li className="feedback">
						<span onClick={() => {this.setState({open: !this.state.open})}}>Обратная связь</span>
						{this.state.open &&
						<div className="hidden">
							<form action="#" onSubmit={e => this.submit(e)}>
								<input type="text" className="titleForm" value={this.state.title} placeholder="Имя"
								       onChange={(e) => this.setState({title: e.target.value})} required/>
								<input type="email" className="email" value={this.state.email} placeholder="E-mail"
								       onChange={(e) => this.setState({email: e.target.value})} required/>
								<textarea name="text" cols="30" rows="10" className="text" value={this.state.text}
								          placeholder="Сообщение"
								          onChange={(e) => this.setState({text: e.target.value})} required/>
								<input type="submit" className="submit" value="Отправить"/>
							</form>
						</div>
						}
					</li>
					<li className="logout"><span onClick={() => this.props.clearStates()}>Выйти из аккаунта</span></li>
				</ul>
			</div>
		)
	}
}

export default withAlert(LeftMenu);