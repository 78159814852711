import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-meerkat";
import AlertTemplate from "react-meerkat-template-basic";

const options = {
	timeout: 5000,
	position: "bottom center"
};

ReactDOM.render(<Provider template={AlertTemplate} {...options}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
